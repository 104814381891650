import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Link from "../views/ex_link.vue";
import Condi from "../views/condi.vue";
import Condi_L from "../views/condi_L.vue";
import Condi_WSS from "../views/condi_WS-S.vue";
import Condi_XL from "../views/condi_XL.vue";
import Condi_WSL from "../views/condi_WS-L.vue";
import Bill from "../views/bill.vue";
import Exch from "../views/exch.vue";
import Reward_L from "../views/reward_L.vue";
import Reward_XL from "../views/reward_XL.vue";
import Reward_WSS from "../views/reward_WS-S.vue";
import Reward_WSL from "../views/reward_WS-L.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/link",
    name: "Link",
    component: Link,
  },
  {
    path: "/condi",
    name: "Condition Page",
    component: Condi,
  },
  {
    path: "/condi_l",
    name: "Condition Page",
    component: Condi_L,
  },
  {
    path: "/condi_xl",
    name: "Condition Page",
    component: Condi_XL,
  },
  {
    path: "/Condi_wss",
    name: "Condition Page",
    component: Condi_WSS,
  },
  {
    path: "/Condi_wsl",
    name: "Condition Page",
    component: Condi_WSL,
  },
  {
    path: "/exch",
    name: "Exchange Page",
    component: Exch,
  },
  {
    path: "/reward_l",
    name: "Reward Page",
    component: Reward_L,
  },
  {
    path: "/reward_xl",
    name: "Reward Page",
    component: Reward_XL,
  },
  {
    path: "/reward_wss",
    name: "Reward Page",
    component: Reward_WSS,
  },
  {
    path: "/reward_wsl",
    name: "Reward Page",
    component: Reward_WSL,
  },
  {
    path: "/bill",
    name: "Invoice History Page",
    component: Bill,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
