import { render, staticRenderFns } from "./reward_WS-L.vue?vue&type=template&id=803a3048&"
import script from "./reward_WS-L.vue?vue&type=script&lang=js&"
export * from "./reward_WS-L.vue?vue&type=script&lang=js&"
import style0 from "./reward_WS-L.vue?vue&type=style&index=0&id=803a3048&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports