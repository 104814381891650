<template>
    <div>
        <center class="red--text">
            <v-container  v-if="suggest[0].pass_condi == '1.000'"  >
                <v-card
                        color="#D9DEBB"
                        dark
                    >
                    <br>
                    <H2 class="green--text mx-5" >
                        ยินดีด้วย ! ผ่านเงื่อนไขยอดขายเติบโต 112% แล้ว                
                    </H2>
                    <br>
                </v-card>
            </v-container>
            <v-container  v-if="suggest[0].pass_condi !== '1.000'"  >
                <v-card
                        color="#FFCDD2"
                        dark
                    >
                    <br>
                    <H2 class="red--text mx-5" >
                        โปรด ซื้อสินค้าเพื่อยอดขายเติบโต 112% เพื่อผ่านเงื่อนไข                    
                    </H2>
                    <br>
                </v-card>
            </v-container>            
        </center>
        <center class="blue--text">
            <br>
            <h2>ส่วนลดและเงินรางวัลสะสม</h2>
            <br>
        </center>  
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#80D8FF"
                        dark
                    >
                        <v-card-title class="black--text" >
                            ส่วนลดเติบโต 112%
                        </v-card-title>
                        <H2 class="black--text mx-5" >
                            ได้ส่วนลด {{ suggest[0].final_reward }} %
                        </h2>
                        <br>               
                        <p class="black--text mx-5" >
                            <strong>{{ suggest[0].next_step }}</strong>
                        </p>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>         

        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#D9DEBB"
                        dark
                    >
                        <v-card-title class="black--text" >
                            ซื้อสินค้าขายดี รับส่วนลด
                        </v-card-title>
                        <P class="black--text mx-5" >
                            24 ตัว ขึ้นไป - รับส่วนลด 1.00%
                        </P>
                        <P class="black--text mx-5" >
                            28 ตัว ขึ้นไป - รับส่วนลด 1.25%                     
                        </P>
                        <P class="black--text mx-5" >
                            ซื้อแล้ว <strong>{{suggest[0].sku}} </strong> Sku                      
                        </P>
                        <H2 v-if="suggest[0].reward_pnt_2 != '0.00' " class="black--text mx-5" >
                            ได้ส่วนลด {{ suggest[0].final_reward }} %
                        </H2>
                        <P v-if="suggest[0].reward_pnt_2 == '0.00' " class="red--text mx-5" >
                            <strong>{{ suggest[0].next_step_2 }} </strong>
                        </P>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="suggest[0].display == 'Y' " >
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#F6E7B4"
                        dark
                    >
                        <v-card-title class="black--text" >
                            ค่าเชาดิสเพลย์ 
                        </v-card-title>
                        <p  v-if="gap_display > 0 " class="black--text mx-5" >
                            ติดตั้ง ดิสเพลย์แล้ว ซื้อเพิ่ม {{ vueNumberFormat( gap_display , {prefix: '',suffix: '',decimal: '.',thousand: ','}) }}  บาทเพื่อได้ค่าเช่า 1,000 บาท  
                        </p>
                        <h2  v-if="gap_display <= 0  " class="black--text mx-5" >
                            ได้ค่าเช่า 1,000 บาท  
                        </h2>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        รูปสินค้า
                    </th>
                    <th class="text-left">
                        ชื่อสินค้า 
                    </th>
                    <th class="text-left">
                        ซื้อ 
                    </th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in sbdgap"
                    :key="item.sku"
                    >
                    <td><v-img :src="item.image" max-height="80px" width="80px"></v-img></td>
                    <td>{{ item.name_th }}</td>
                    <td>..</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <br>
        </v-container>
        <br>
    </div>
  </template>
  
  
<script>

import axios from "axios";

    export default {
      data () {
        return {
          sbdgap: [],
          suggest : [],
          total : 0,
          gap_display : 0 ,
        }
    },
    created() {
        this.get_sbdgap()
        this.get_suggest()
    },
    methods: {
        get_sbdgap(){

            axios.get(process.env.VUE_APP_WS+"getsbdgap.php",{
                        params: {
                            scode : this.$store.getters.getstore_code ,
                        }
                    }).then(result=>{
                this.sbdgap  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + result.data )


            })
            },

            get_suggest(){

            axios.get(process.env.VUE_APP_WS+"getsuggest.php",{
                        params: {
                            scode : this.$store.getters.getstore_code ,
                        }
                    }).then(result=>{
                
                    this.suggest  = JSON.parse(JSON.stringify(result.data))

                    this.gap_display = 50000 - this.suggest[0].sale_volume
                    
                console.log( "check_suggest data = " + this.suggest[0].final_reward )


            })
            },


    },
    
    }
  </script>
  
 <style>
    @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

    html, body {
        font-family: 'Prompt', sans-serif;
    }

    #app {
        font-family: 'Prompt', sans-serif;
    }

    .p {
    font-size: 0.75em;
}

</style>