<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="font-weight-bold mb-3">ชื่อที่แสดงใน line : {{name}}</h3>
      </v-col>
    </v-row>  
    <v-row class="text-center">
      <v-col class="mb-4">
            <v-text-field
            v-model="per_id"
            label="รหัสร้านค้า"
            required
        ></v-text-field>
      </v-col>  
    </v-row>  

    <v-row class="text-center">
      <v-col class="mb-4">
        <center >
          <v-btn large color="success" @click="check_store">ตรวจสอบรายละเอียดร้านค้า</v-btn>
        </center>     
      </v-col>  
    </v-row> 

    <v-row v-if="show_emp_code" class="text-center">
      <v-col class="mb-4">
          <h3>{{store_name}}</h3>
          <h4>{{store_address}}</h4>
      </v-col>  
    </v-row> 

    <v-row class="text-center">
      <v-col class="mb-4">
            <v-text-field
            v-model="otp"
            label="OTP"
            required
        ></v-text-field>
      </v-col>  
    </v-row>     

    <v-row v-if="show_btn" class="text-center">
      <v-col class="mb-4">
        <center >
          <v-btn large color="success" @click="check_otp">ลงทะเบียน</v-btn>
        </center>     
      </v-col>  
    </v-row>     

  </v-container>
</template>

<script>

import liff from "@line/liff";
import axios from "axios";

export default {
  data () {
    return {
      name: '',
      per_id:'',
      otp : '',
      show_emp_code : 1 ,
      show_otp : 1 ,
      show_btn : 0 ,
      imgURL: '',
      uid :'',
      items: null,
      app_link:'',
      store_name : '',
      store_address : '',
    }
  },
  created() {
    let uri = window.location.search.substring(); 
    let params = new URLSearchParams(uri);
    this.app_link = params.get("app");
    
    // -- online with line liff mode 
    this.initializeLiff();
    this.$store.dispatch('ac_setstore_code','')

    // -- test localhost
    /*
      this.$store.dispatch('ac_setstore_code','51003131')
      this.$store.dispatch('ac_setstore_class','HFSWS-L')
      if ( this.app_link == "condi" || this.app_link == "reward" ){
        this.$router.push('/'+this.app_link+'_'+this.$store.getters.getstore_class.substring(3,7).toLowerCase().replace("-", ""))

      }else{                                        
        this.$router.push('/'+this.app_link)
      }
      */
    //-- end of test localhost

  },
  methods: {
    initializeLiff: function () {

      liff.init({
    liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    })
    .then(() => {
        // start to use LIFF's api
        if (liff.isLoggedIn()) {

            liff.getProfile()
            .then(profile => {

              this.name =  profile.displayName 
              this.uid = profile.userId

              this.check_registed()

              console.log(" app => " ,this.app_link)

            })
            .catch((err) => {
              console.log('error', err)
            })                        

            //me.$router.push({ name: 'login' })
          }else{

            liff.login();

          }
    })
    .catch((err) => {
        console.log(err);
    });

    },

    logout: function () {

      if (liff.isLoggedIn()) {
        liff.logout()
        this.$router.push({ name: 'home' })
      }
    },

       check_user(){

            axios.get("",{
                        params: {
                            suid: this.uid ,
                            scode: this.per_id,
                            spass: this.birth_id
                            
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))

                console.log( "check_user data = " + result.data )
            
            })
      },

      check_registed(){

            axios.get(process.env.VUE_APP_WS+"getlineid.php",{
                        params: {
                            scode: this.uid
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + this.items[0].custid  )

                if ( this.items[0].custid != null ){
                    this.$store.dispatch('ac_setstore_code',this.items[0].custid)
                    this.$store.dispatch('ac_setstore_class',this.items[0].classid)
                 
                    if ( this.app_link == "condi" || this.app_link == "reward" ){
                      this.$router.push('/'+this.app_link+'_'+this.items[0].classid.substring(3,7).toLowerCase().replace("-", ""))

                    }else{                                        
                      this.$router.push('/'+this.app_link)
                    }
                }

            })
      },

      check_store(){

          axios.get(process.env.VUE_APP_WS+"getstoredetail.php",{
                      params: {
                          scode: this.per_id
                      }
                  }).then(result=>{
              this.items  = JSON.parse(JSON.stringify(result.data))
              console.log( "check_store data = " + this.items[0].custid  )

              if ( this.items[0].custid != null ){

                this.show_emp_code = 1
                this.store_name = this.items[0].name
                this.store_address = this.items[0].address
                this.show_btn = 1
              
              }else{
                  alert("ไม่เจอรหัสร้านค้านี้ในระบบ")
              }

          })
      },

      check_otp(){

          axios.get(process.env.VUE_APP_WS+"post_store.php",{
                      params: {
                          suid: this.uid ,
                          scode: this.per_id ,
                          spassword: this.otp ,
                      }
                  }).then(result=>{
              this.items  = JSON.parse(JSON.stringify(result.data))
              console.log( "check_registed data = " + result.data )

              if ( result.data == "success" ){
                alert(" รหัสร้านค้านี้ได้ลงทะเบียนเรียบร้อยแล้ว")
                this.$router.push('/condi')
              }else{
                alert(" ลงทะเบียนไม่สำเร็จ")
              }

          })
        },

      post_register(){

            axios.get("",{
                        params: {
                            suid: this.uid ,
                            scode: this.per_id ,

                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + result.data )

                if ( result.data == "success" ){

                  alert(" รหัสพนักงานนี้ได้ลงทะเบียนเรียบร้อยแล้ว")
                  
                }else{
                  alert(" ลงทะเบียนไม่สำเร็จ")
                }


            })
      },

  },
};
</script>
